import classNames from 'classnames';
import { HTMLAttributes, useMemo } from 'react';
import { useTranslation } from 'next-i18next';

import { ViaFerrataStatus as Status } from './types';
import useViaFerrataStatus from './useViaFerrataStatus';

type Props = {
  className?: string;
};

function ViaFerrataStatus({ className }: Props) {
  const { t } = useTranslation('common');
  const { status, isLoading } = useViaFerrataStatus();

  const Icon = useMemo(() => {
    if (isLoading) return IconLoading;
    switch (status) {
      case Status.OPENED:
        return IconOpen;
      case Status.READY:
        return IconReady;
      case Status.CLOSED:
        return IconClose;
    }
  }, [isLoading, status]);

  if (!isLoading && !status) return null;
  return (
    <div
      className={classNames(
        'PageContent__viaFerrata',
        {
          'PageContent__viaFerrata--loading': isLoading,
          'PageContent__viaFerrata--opened': status === Status.OPENED,
          'PageContent__viaFerrata--ready': status === Status.READY,
          'PageContent__viaFerrata--closed': status === Status.CLOSED,
        },
        className,
      )}
    >
      <div className="PageContent__viaFerrataBadge">
        {/* @ts-ignore */}
        <Icon className="PageContent__viaFerrataIcon" />
        <div className="PageContent__viaFerrataTitle">
          {t(`VIA_FERRATA_${status ? status : 'Loading'}_TITLE`)}
        </div>
      </div>
      {status && (
        <div className="PageContent__viaFerrataDescription">
          <p>{t(`VIA_FERRATA_${status}_DESCRIPTION`)}</p>
        </div>
      )}
    </div>
  );
}

export default ViaFerrataStatus;

function IconOpen(props: HTMLAttributes<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M8 12L11 15L16 9"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function IconClose(props: HTMLAttributes<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.5 15.5L8.50073 8.5M8.50148 15.5L15.5008 8.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function IconReady(props: HTMLAttributes<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.08594 3.99414L6.41749 4.89014C6.72135 5.7113 7.36878 6.35873 8.18994 6.66259L9.08594 6.99414L8.18994 7.32569C7.36878 7.62955 6.72135 8.27698 6.41749 9.09814L6.08594 9.99414L5.75439 9.09814C5.45053 8.27698 4.8031 7.62955 3.98194 7.32569L3.08594 6.99414L3.98194 6.66259C4.8031 6.35873 5.45053 5.7113 5.75439 4.89014L6.08594 3.99414Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0386 14.9951C14.4376 19.861 11.4306 21.7441 10.0581 22.0028C7.42795 21.6554 5.70783 20.3663 4.63036 18.8624M15.0386 14.9951L12.0176 12.9777M15.0386 14.9951L17.5967 9.99416M4.63036 18.8624C3.4326 17.1906 2.98129 15.1727 3.00059 13.9617C3.00175 13.8889 3.07915 13.845 3.14417 13.8776C4.65834 14.6371 7.95744 15.7109 12.0176 12.9777M4.63036 18.8624C6.72157 19.4846 9.39667 18.4653 10.1715 17.9526M12.0176 12.9777L15.0236 8.38354M17.5967 9.99416L20.8663 3.71445C21.1656 3.14192 20.9454 2.43462 20.3743 2.13425C19.844 1.85533 19.1891 2.02342 18.8579 2.52349L15.0236 8.38354M17.5967 9.99416L15.0236 8.38354"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function IconLoading(props: HTMLAttributes<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 3V6"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12 18V21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M21 12H18"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6 12H3"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18.3635 5.63672L16.2422 7.75804"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.75804 16.2422L5.63672 18.3635"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18.3635 18.3635L16.2422 16.2422"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.75804 7.75804L5.63672 5.63672"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
