import useSWR from 'swr';

import { GetViaFerrataStatusResult } from 'pages/api/via-ferrata-status';

function useViaFerrataStatus() {
  const { data, ...other } = useSWR<GetViaFerrataStatusResult>(
    '/api/via-ferrata-status',
  );

  return {
    status: data?.status ?? null,
    isLoading: data === undefined,
    ...other,
  };
}

export default useViaFerrataStatus;
