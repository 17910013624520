export enum ViaFerrataStatus {
  /**
   * Via ferrata open
   *
   * The via ferrata is open. Climbing is allowed.
   *
   * Warning: If you’re coming from a more distant location,
   * such as more than an hour away, please check the weather
   * forecast carefully. The ferrata status is managed directly
   * by the administrator, but that doesn’t mean it can’t start
   * raining within the next hour. As responsible mountaineers,
   * you need to monitor that yourselves.
   */
  OPENED = 'Open',

  /**
   * Preparation for opening
   *
   * This is an advance notice from the ferrata administrator. If there
   * is no unexpected change in weather, the via ferrata will likely
   * be opened within one to two hours. That means it’s currently in
   * a drying phase.
   */
  READY = 'Ready',

  /**
   * Via ferrata closed
   *
   * One of the properties of sandstone is that when it’s wet or damp,
   * it can crack or crumble due to its fragility. This can damage
   * the fixed protection points. For this reason, the via ferrata
   * is regularly closed even during the summer season.
   * Please respect this status. Entering during closure is subject
   * to fines by the Municipal Police. In good weather (sun and wind),
   * the ferrata is usually reopened between 12 PM and 12 AM.
   */
  CLOSED = 'Close',
}
